import image1 from './images/photo_main1.jpg';
import image2 from './images/photo_main2.jpg';
import image3 from './images/photo_main3.jpg';

document.addEventListener('DOMContentLoaded', () => {
    const images = [
        image1,
        image2,
        image3,
    ];

    const randomImage = images[Math.floor(Math.random() * images.length)];
    document.getElementById('randomImage').src = randomImage;
});
